// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
const jQuery = require('jquery')
window.$ = jQuery
window.jQuery = jQuery

import IMask from 'imask';
window.IMask = IMask;

require('bootstrap/dist/js/bootstrap.bundle.min')
require('admin-lte')
require('admin-lte/plugins/chart.js/Chart.js')
require('admin-lte/plugins/daterangepicker/daterangepicker.js')
require('admin-lte/plugins/select2/js/select2.full.js')
require('moment/locale/ja')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Swal from 'sweetalert2'

(function ($) {
  $.fn.torstr = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  $.fn.tableHighlighter = function(id) {
    let $el = $(id);
    $el.addClass("table-warning");
    $el.siblings().removeClass("table-warning");
  };

  $(function() {
    $(".select2").select2({
      theme: 'bootstrap4'
    });
  });
})(jQuery);
